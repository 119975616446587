<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>小程序</a-breadcrumb-item>
              <a-breadcrumb-item>参数配置</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <div>
          <a-form-model
            ref="ruleForm"
            :rules="rules"
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              ref="xcxAppId"
              prop="xcxAppId"
              label="小程序 AppId"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.xcxAppId"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="xcxSecret"
              prop="xcxSecret"
              label="小程序 Secret"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.xcxSecret"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="gzhAppId"
              prop="gzhAppId"
              label="公众号 AppId"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.gzhAppId"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="gzhSecret"
              prop="gzhSecret"
              label="公众号 Secret"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.gzhSecret"
              />
            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
              <a-button type="primary" @click="onSubmit"> 提交 </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '路径',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
  },
  {
    title: '模块包含子权限',
    dataIndex: 'permissions',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      value: 'name',
      key: '',
      selectedRowKey: [],
      Id: '',
      form: {
        id: '',
        xcxAppId: '',
        xcxSecret: '',
        gzhAppId: '',
        gzhSecret: '',
      },
      rules: {
        xcxAppId: [
          {
            required: true,
            message: '小程序 AppId不能为空',
            trigger: 'blur',
          },
        ],
        xcxSecret: [
          {
            required: true,
            message: '小程序 Secret不能为空',
            trigger: 'blur',
          },
        ],
        gzhAppId: [
          {
            required: true,
            message: '公众号 AppId不能为空',
            trigger: 'blur',
          },
        ],
        gzhSecret: [
          {
            required: true,
            message: '公众号 Secret不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          this.postxcxconfig(this.form)
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    async postxcxconfig(form) {
      try {
        const res = await http.postxcxconfig(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.getxcxconfig()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
    },
    async getxcxconfig() {
      try {
        const res = await http.getxcxconfig()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form.xcxAppId = data.xcxAppId.value
          this.form.xcxSecret = data.xcxSecret.value
          this.form.gzhAppId = data.gzhAppId.value
          this.form.gzhSecret = data.gzhSecret.value
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getxcxconfig()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getxcxconfig()
    this.$store.commit('selectKeys')
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
ul li {
  list-style: none;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 5px;
  border: 1px solid #d9d9d9;
  width: 90px;
  text-align: center;
}
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
</style>
