var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%","height":"60px"}},[_c('div',{staticClass:"Enter-top"},[_c('div',[_c('div',[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("小程序")]),_c('a-breadcrumb-item',[_vm._v("参数配置")])],1)],1)])])]),_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        padding: '24px',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',[_c('a-form-model',{ref:"ruleForm",attrs:{"rules":_vm.rules,"model":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{ref:"xcxAppId",attrs:{"prop":"xcxAppId","label":"小程序 AppId"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.name.onFieldBlur()
                }},model:{value:(_vm.form.xcxAppId),callback:function ($$v) {_vm.$set(_vm.form, "xcxAppId", $$v)},expression:"form.xcxAppId"}})],1),_c('a-form-model-item',{ref:"xcxSecret",attrs:{"prop":"xcxSecret","label":"小程序 Secret"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.name.onFieldBlur()
                }},model:{value:(_vm.form.xcxSecret),callback:function ($$v) {_vm.$set(_vm.form, "xcxSecret", $$v)},expression:"form.xcxSecret"}})],1),_c('a-form-model-item',{ref:"gzhAppId",attrs:{"prop":"gzhAppId","label":"公众号 AppId"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.name.onFieldBlur()
                }},model:{value:(_vm.form.gzhAppId),callback:function ($$v) {_vm.$set(_vm.form, "gzhAppId", $$v)},expression:"form.gzhAppId"}})],1),_c('a-form-model-item',{ref:"gzhSecret",attrs:{"prop":"gzhSecret","label":"公众号 Secret"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.name.onFieldBlur()
                }},model:{value:(_vm.form.gzhSecret),callback:function ($$v) {_vm.$set(_vm.form, "gzhSecret", $$v)},expression:"form.gzhSecret"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 8, offset: 8 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 提交 ")])],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }